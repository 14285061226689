import { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { InstantSearch } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
import { NEW_BASE_FRAME_NAMES } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { algoliaClient } from '@services/algolia/client';
import { ALGOLIA_REFINEMENTS_ALLTOPS, RefinementListProps } from '@utils/algolia';
import { useHasMounted } from '@utils/hooks';
import { CopyFields } from '@ts/contentful';
import { FilterHeader, InfiniteHits } from '@components/search';
import { Flex } from '@components/common';
import { FilterProvider } from '@context';
import { NormalizedCollection } from '@ts/index';
import RefinementPrice from '../RefinementPrice';
import styles from './FrameFiltering.module.scss';

const FacetDropdown = dynamic(() => import('@components').then(mod => mod.FacetDropdown));
const FilterAccordionPanel = dynamic(() => import('@components').then(mod => mod.FilterAccordionPanel));
const RefinementList = dynamic(() => import('@components').then(mod => mod.RefinementList));

type FrameFilteringProps = {
	type: 'all-tops' | 'buildflow' | 'search' | 'bf-all-tops' | 'eyeglasses' | 'sunglasses';
	/**
	 * The name of the Algolia index to search.
	 * @default 'shopify_products_recently_ordered_count_desc'
	 * @import ALGOLIA_SEARCH_INDEXES.US.RECENTLY_ORDERED in '@utils/algolia'
	 */
	indexName: string;
	/**
	 * Refinement lists to display.
	 * @default ALGOLIA_REFINEMENTS_ALLTOPS (imported from @utils/algolia)
	 */
	refinementList?: Array<RefinementListProps>;
	/**
	 * Determines list of collections to pull filter information from.
	 * @default COLLECTION_LISTS.ACTIVE (imported from @constants/contentful)
	 */
	collectionList?: string;
	router?: ReturnType<typeof createInstantSearchRouterNext>;
	frame?: NEW_BASE_FRAME_NAMES;
	isSunglassesRoute?: boolean;
	children?: ReactNode;
	plpAccordion?: CopyFields;
	cardLocations?: number[];
	cardsByLocation?: Record<number, JSX.Element>;
	collection?: NormalizedCollection;
};

/**
 * @name FrameFiltering
 * Top-level of component tree for filtering on the All Tops and Buildflow pages.
 * InstantSearch (by Algolia) wraps the FilterProvider, FilterHeader, both FilterPanel components, and InfiniteHits components.
 */
const FrameFiltering = ({
	type,
	indexName,
	refinementList = ALGOLIA_REFINEMENTS_ALLTOPS,
	collectionList = COLLECTION_LISTS.ACTIVE,
	router = undefined,
	frame = undefined,
	cardLocations,
	cardsByLocation,
	isSunglassesRoute,
	children,
	plpAccordion,
	collection,
}: FrameFilteringProps) => {
	const hasMounted = useHasMounted();
	const containerClasses = cn(styles['container'], {
		[styles['buildflow']]: type === 'buildflow',
		[styles['bf-all-tops']]: type === 'bf-all-tops',
		[styles['allTops']]: type === 'all-tops' || type === 'eyeglasses' || type === 'sunglasses',
	});

	return (
		<InstantSearch
			indexName={indexName}
			routing={router && { router }}
			searchClient={algoliaClient}
			future={{
				preserveSharedStateOnUnmount: false, // Removes console warning: https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-future
			}}
		>
			<FilterProvider
				collectionList={collectionList}
				frameShapeOverride={frame}
				type={type}
				isSunglassesRoute={isSunglassesRoute}
			>
				{hasMounted && (
					<FilterHeader plpAccordion={plpAccordion}>
						{type === 'buildflow' &&
							refinementList.map(r => (
								<FacetDropdown
									key={'buildflow-' + r.type}
									buttonText={r.label}
									closeOnChange={true}
									refinementListProps={{ ...r }}
								/>
							))}
					</FilterHeader>
				)}
				<Flex gap={3} className={containerClasses}>
					{(type === 'all-tops' ||
						type === 'search' ||
						type === 'bf-all-tops' ||
						type === 'eyeglasses' ||
						type === 'sunglasses') && (
						<FilterAccordionPanel>
							<Flex column justify='between'>
								{refinementList.map(r => (
									<div key={r.type}>
										<RefinementList page={type} listType='accordion' {...r} />
										{r.type === 'color' && type !== 'eyeglasses' && type !== 'sunglasses' && (
											<RefinementPrice />
										)}
									</div>
								))}
							</Flex>
						</FilterAccordionPanel>
					)}
					<InfiniteHits cardLocations={cardLocations} cardsByLocation={cardsByLocation} collection={collection} />
				</Flex>
				{children}
			</FilterProvider>
		</InstantSearch>
	);
};

export default FrameFiltering;
