import { Checkbox, Paragraph } from '@components';
import { MARVEL_COLLECTIONS, MARVEL_COLLECTION_LABEL, SPARKLE_COLLECTIONS, SPARKLE_COLLECTION_LABEL } from '@utils/algolia';
import styles from './RefinementOption.module.scss';

function RefinementOption({ label, isRefined, refine, type, value }) {
	return (
		<li key={`refinement-value-${label}`}>
			<Checkbox
				checked={isRefined}
				option={value}
				handler={v => {
					if (v == MARVEL_COLLECTION_LABEL) {
						MARVEL_COLLECTIONS.forEach(collection => {
							refine(collection);
						});
					} else if (v == SPARKLE_COLLECTION_LABEL) {
						SPARKLE_COLLECTIONS.forEach(collection => {
							refine(collection);
						});
					} else {
						refine(v);
					}
				}}
				className={styles.checkbox}
				hoverable={false}
				dataTags={{
					'data-filter-refinement-option': label,
					[`data-bf-filter-${type}`]: value,
				}}
			>
				<Paragraph>{label}</Paragraph>
			</Checkbox>
		</li>
	);
}

export default RefinementOption;
