/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Configure,
	useCurrentRefinements,
	useInfiniteHits,
	useInstantSearch,
	usePagination,
	useSearchBox,
} from 'react-instantsearch';
import { m } from 'framer-motion';
import { VirtuosoGrid } from 'react-virtuoso';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useFilterContext } from '@context';
import { Container, Flex, Heading, Loading, Paragraph } from '@components';
import { useIsProductVariantSoldOut } from '@utils/hooks';
import { NormalizedCollection } from '@ts/index';
import { getParsedHandleandColor } from '@utils/shopify';
import Hit from '../Hit/Hit';
import NoResults from '../NoResults';
import styles from './InfiniteHits.module.scss';

/**
 * InfiniteHits
 *
 * @description Custom version of Algolia's "InfiniteHits" component using the "useInfiniteHits" hook.
 * @source https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/
 *
 * !!IMPORTANT!!
 * Do NOT use the Next Router's "useRouter" hook in this component; it will break InstantSearch SSR Provider.
 */
const InfiniteHits = ({
	cardLocations,
	cardsByLocation,
	collection,
}: {
	cardLocations?: number[];
	cardsByLocation?: Record<number, JSX.Element>;
	collection?: NormalizedCollection;
}) => {
	const demosMap = {
		men: 'mens',
		women: 'womens',
		kids: 'kids',
	};
	const { status } = useInstantSearch();
	const { refine, currentRefinement } = usePagination();
	const { hits: hitsData, showMore, isLastPage } = useInfiniteHits();
	const { items } = useCurrentRefinements();
	const { checkIfSoldOut } = useIsProductVariantSoldOut();
	const { frameShape, isSunglassesRoute, isOpen, type, virtuosoRef, priceFilter, algoliaRefreshKey } = useFilterContext();
	const { locale, query: routerQuery } = useRouter();
	const demo = routerQuery.demo as (typeof demosMap)[keyof typeof demosMap];
	const isEyeglasses = type === 'eyeglasses';
	const isSunglasses = type === 'sunglasses';
	const isAll = demo === 'all';
	const isBaseFramePLP = isEyeglasses || isSunglasses || isAll;
	const isBuildflow = type === 'buildflow' || type === 'bf-all-tops';
	const isSearch = type === 'search';
	const { query, refine: searchRefine } = useSearchBox();
	const metalRegex = /metal/i;
	const productHandles = useMemo(() => collection?.products.map(product => product.handle), [collection]);
	let hits = hitsData;

	if (isEyeglasses || isSunglasses) {
		const frameDict = new Set([]);
		const acetate = [];
		const metal = [];

		hits.forEach(hit => {
			const { handle } = hit;
			const { handle: minHandle } = getParsedHandleandColor(handle as string);
			if (!productHandles.includes(minHandle)) return;
			const isMetal = metalRegex.test(String(handle));
			const frameName = String(handle).split('-')[1];
			const frameKey = isMetal ? frameName + '-metal' : frameName;

			if (!frameDict.has(frameKey)) {
				frameDict.add(frameKey);
				isMetal ? metal.push(hit) : acetate.push(hit);
			}
		});

		hits = [...acetate, ...metal];
	}

	// Filters out Sun Tops on the '/sunglasses' route
	if (isSunglassesRoute)
		hits = hits.filter(hit => {
			const foundHandle = (hit?.handle as string) ?? false;

			if (foundHandle && foundHandle.includes('sun-top')) return;
			else return hit;
		});

	hits = hits.filter(hit => {
		const isSoldOut = checkIfSoldOut(hit.handle as string, hit.variant_title as string);
		return !isSoldOut;
	});

	const hitsLength = hits.length;
	useEffect(() => {
		refine(0); // Reset pagination
	}, [algoliaRefreshKey]);

	const handleOnScroll = useCallback(() => {
		if (isLastPage) return;
		showMore();
	}, [isLastPage, showMore]);
	const filterDict: Record<string, string> = {
		eyeglasses: `option_names: lens AND product_type: BASE_FRAME_SR AND options.lens: Basic AND options.rx_type: "Single Vision" ${demosMap[demo] ? `AND tags: ${demosMap[demo]}` : ''} AND inventory_available: true`,
		sunglasses: `option_names: lens AND product_type: BASE_FRAME_SR AND options.rx_type: "Single Vision" ${demosMap[demo] ? `AND tags: ${demosMap[demo]}` : ''} AND inventory_available: true`,
	};

	const resultFilters = useCallback(() => {
		return (
			filterDict[type] ??
			`option_names: frame AND options.frame: ${frameShape} AND inventory_available: true ${
				priceFilter ? `AND ${priceFilter}` : ''
			}`
		);
	}, [frameShape, priceFilter, demo]);

	const loadingJsx = status === 'loading' && (
		<Flex className={styles['loading-container']}>
			<Flex column align='center'>
				<Loading className={styles['loading']} phrase='Loading Results...' />
			</Flex>
		</Flex>
	);

	const stalledJsx = status === 'stalled' && hitsLength === 0 && (
		<Container className={styles['no-results']}>
			<Heading tag='h5'>No results found.</Heading>
			<Paragraph>Try a different color, design, or collection.</Paragraph>
		</Container>
	);

	useEffect(() => {
		type === 'sunglasses' && searchRefine('SUN');
	}, [type]);
	if (isEyeglasses && items.length === 0) {
		cardLocations?.forEach(location => {
			hits.splice(location, 0, null);
		});
	}

	const CardHit = ({ index }) => <React.Fragment key={`extra-card-${index}`}>{cardsByLocation[index]}</React.Fragment>;
	return (
		<>
			{/* TODO: remove hardcoded hits per page once found a better solution for Base Frame PLPs */}
			{!isSearch && <Configure hitsPerPage={isBaseFramePLP ? 160 : 50} filters={resultFilters()} key={algoliaRefreshKey} />}
			<m.div
				className={cn(styles['container'], {
					[styles['buildflow']]: isBuildflow,
					[styles['show-refinements']]: !!items?.length,
				})}
			>
				{isSearch && hitsLength === 0 && <NoResults query={query} handleSearch={searchRefine} />}
				{loadingJsx}
				<VirtuosoGrid
					ref={virtuosoRef}
					style={{ minHeight: isBuildflow ? '100%' : '100vh' }}
					className={styles['virtuoso']}
					listClassName={cn(styles['tops'], {
						[styles['tops--double']]: isBuildflow,
						[styles['tops--is-open']]: !isBuildflow && isOpen,
					})}
					data={hits}
					totalCount={hitsLength}
					overscan={{ main: 1000, reverse: 2000 }}
					useWindowScroll={!isBuildflow}
					endReached={handleOnScroll}
					itemContent={(index, hit) =>
						!hit ? (
							(hit === null || !(hit.handle as string).includes('metal')) &&
							isEyeglasses &&
							cardLocations.includes(index) ? (
								<CardHit index={index} />
							) : null
						) : (
							<Hit
								locale={locale}
								key={hit.objectID}
								hit={hit}
								position={currentRefinement + index + 1}
								type={type}
								collection={collection}
							/>
						)
					}
				/>
				{stalledJsx}
			</m.div>
		</>
	);
};

export default InfiniteHits;
