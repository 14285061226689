/* eslint-disable max-lines */
import React, { ReactNode, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import variables from '@styles/export.module.scss';
import { CopyFields } from '@ts/contentful';
import {
	Accordian,
	Body,
	Caption,
	Chevron,
	Container,
	ContentfulRichTextRenderer,
	Flex,
	Heading,
	Paragraph,
	Title,
} from '@components';
import { useHasMounted, useIsMobile, useWindowDimensions } from '@utils/hooks';
import { BASE_FRAME_COST, EXTRA_EXTRA_LARGE_WIDTH, LOCALE_CODES, LOCALE_DICT, MEDIUM_MAX_WIDTH } from '@constants';
import { isEmpty } from '@utils/objects';
import { formatCurrency } from '@utils/shopify';
import styles from './PLPWrapper.module.scss';

type PLPWrapperProps = {
	plpAccordion?: CopyFields;
	additionalInformationTitle?: string;
	additionalInformationCards?: Array<CopyFields>;
	children: ReactNode;
	isBaseFramePLP?: boolean;
};

const PLPAccordion = ({
	plpAccordion,
	isAllTops = false,
	onOpenChange = () => null,
	titleChildren,
	isBaseFramePLP,
}: {
	plpAccordion: PLPWrapperProps['plpAccordion'];
	isAllTops?: boolean;
	onOpenChange?: (isOpen: boolean) => void;
	titleChildren?: ReactNode;
	isBaseFramePLP?: boolean;
}) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isMobile = useIsMobile();
	const [isAccordionOpen, setIsAccordionOpen] = useState(false);
	const isPLPPriceTest = useFeatureIsOn('is-plp-price-test');

	const subtitle = (
		<>
			<Caption>
				Starting from{' '}
				{formatCurrency(
					{
						amount: BASE_FRAME_COST[locale],
						currencyCode: LOCALE_DICT[locale].currencyCode,
						locale,
					},
					showCurr
				)}
			</Caption>
			<Body>including Rx</Body>
		</>
	);

	return (
		<Accordian
			className={styles.accordion}
			title={isAllTops ? null : plpAccordion.title}
			titleChildren={titleChildren}
			onClick={() => {
				setIsAccordionOpen(!isAccordionOpen);
				onOpenChange(!isAccordionOpen);
			}}
			isOpen={isAccordionOpen}
			headingTag='h4'
			subtitle={isPLPPriceTest && isBaseFramePLP && subtitle}
		>
			<Container className={styles.content} borderRadius={3} backgroundColor='gray1' pad={4}>
				<Paragraph color={variables.gray4}>
					{!!plpAccordion?.richTextDescription
						? ContentfulRichTextRenderer(isMobile, {
								text: plpAccordion?.richTextDescription,
								maxWidth: 'Auto',
								inlinePadding: 'None',
								textColor: variables.gray4,
							})
						: plpAccordion.description.split('\n').map((text, index) => {
								if (text.includes('__')) {
									return (
										<Title key={index} style={{ color: variables.gray4 }}>
											{text.replaceAll('__', '')}
										</Title>
									);
								}
								return (
									<Paragraph key={index} style={{ color: variables.gray4 }}>
										{text}
									</Paragraph>
								);
							})}
				</Paragraph>
			</Container>
		</Accordian>
	);
};

const PLPAdditionalInformation = ({
	additionalInformationTitle,
	additionalInformationCards,
	isAllTops = false,
}: {
	additionalInformationTitle: PLPWrapperProps['additionalInformationTitle'];
	additionalInformationCards: PLPWrapperProps['additionalInformationCards'];
	isAllTops?: boolean;
}) => {
	const isMobile = useIsMobile();
	const { width } = useWindowDimensions();

	const [activeSlide, setActiveSlide] = useState(0);
	const swiperRef = useRef(null);
	const nextElRef = useRef(null);
	const prevElRef = useRef(null);

	let cardCount = 1;

	if (width >= MEDIUM_MAX_WIDTH) cardCount = 2;
	if (width >= EXTRA_EXTRA_LARGE_WIDTH) cardCount = 4;

	return (
		<Flex className={`${styles.additionalInfo} ${isAllTops ? styles['additionalInfo--isAllTops'] : ''}`} column align='start'>
			<Heading className={styles['additionalInfo--header']} tag='h3'>
				{additionalInformationTitle}
			</Heading>
			<Swiper
				ref={swiperRef}
				className={styles['additionalInfo--swiper']}
				direction='horizontal'
				spaceBetween={8}
				modules={[Navigation, FreeMode]}
				slidesPerView={cardCount}
				slidesPerGroup={1}
				style={{ width: '100%' }}
				onInit={swiper => setActiveSlide(swiper.activeIndex)}
				onSlideChange={swiper => setActiveSlide(swiper.activeIndex)}
			>
				{additionalInformationCards.map(card => (
					<SwiperSlide key={card.slug} className={styles.slide}>
						<Container className={styles.slideContent} borderRadius={3} backgroundColor='gray1' pad={4}>
							<Title className={styles.title}>{card.title}</Title>
							<Paragraph>{card.description}</Paragraph>
						</Container>
					</SwiperSlide>
				))}
			</Swiper>
			<Flex
				className={`${styles['navigation-container']} ${cardCount === 1 || cardCount === 4 ? styles['hidden'] : ''}`}
				justify='end'
				gap={3}
				fullWidth
			>
				<button
					ref={prevElRef}
					className={`${styles['button-prev']} ${activeSlide === 0 ? styles['disabled'] : ''}`}
					aria-label='previous slide'
					onClick={() => swiperRef && swiperRef.current && swiperRef.current.swiper.slidePrev()}
				>
					<Chevron direction='left' />
				</button>
				<button
					ref={nextElRef}
					className={`${styles['button-next']} ${activeSlide + (isMobile ? 1 : 2) >= swiperRef?.current?.swiper?.slides?.length ? styles['disabled'] : ''}`}
					aria-label='next slide'
					onClick={() => swiperRef && swiperRef.current && swiperRef.current.swiper.slideNext()}
				>
					<Chevron direction='right' />
				</button>
			</Flex>
		</Flex>
	);
};

const PLPWrapper = ({
	plpAccordion,
	additionalInformationTitle,
	additionalInformationCards,
	isBaseFramePLP = false,
	children,
}: PLPWrapperProps) => {
	const isMounted = useHasMounted();

	if (!isMounted) return null;

	return (
		<>
			{plpAccordion && !isEmpty(plpAccordion) && (
				<PLPAccordion isBaseFramePLP={isBaseFramePLP} plpAccordion={plpAccordion} />
			)}
			{children}
			{additionalInformationTitle && additionalInformationCards && (
				<PLPAdditionalInformation
					additionalInformationTitle={additionalInformationTitle}
					additionalInformationCards={additionalInformationCards}
				/>
			)}
		</>
	);
};

PLPWrapper.Accordion = PLPAccordion;
PLPWrapper.AdditionalInformation = PLPAdditionalInformation;

export default PLPWrapper;
